<p-toast />
<div class="m-3">
	<p-card header="AUD-Previne" subheader="Bem vindo(a)!" [style]="{width: '100%', 'min-height': '600px'}">
		<div class="grid">
			<div class="col-2 col-offset-2">
				<img src="assets/img/econobot.svg" alt="" style="width: 100%; height: auto;">
			</div>
			<div class="col-6">
				<div class="mt-4">
					<markdown src="/pages/home.md"></markdown>
				</div>
			</div>
		</div>
	</p-card>

</div>