import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environment/environment';
import { SustentabilidadeModule } from '../../sustentabilidade/sustentabilidade.module';
import { AudRole, MSRole, MixedRoles } from '../../../types/auth.types';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { GestorModule } from '../../gestor/gestor.module';
import { IndicadoresModule } from '../../indicadores/indicadores.module';

export type CustomRootMenuItem = CustomMenuItem & {
	slug: string;
	label: string;
	order: number;
}
export type CustomMenuItem = MenuItem & {
	slug?: string;
	parent_slug?: string;
	roles?: string[];
	items?: CustomMenuItem[];
}

@Injectable({
	providedIn: 'root'
})
export class MenuService
{
	constructor(
		protected authService: AuthService,
	)
	{
		this.authService.roles.subscribe((mixedRoles: MixedRoles) =>
		{
			// console.log(mixedRoles);
			this.roles = mixedRoles.roles?.map((role: AudRole) => role.roleId?.toLocaleLowerCase()) as string[];
			this.msRoles = mixedRoles.msRoles?.map((role: MSRole) => role.value?.toLocaleLowerCase()) as string[];
			this.montaMenu2();
		});
		this.authService.profile.subscribe((result: any) =>
		{
			this.profile = result?.profile;
			// console.log(this.profile);
			this.montaMenu2()
		})
	}

	roles: string[] = [];
	msRoles: string[] = [];
	items: BehaviorSubject<CustomMenuItem[]> = new BehaviorSubject<CustomMenuItem[]>([]);
	profile?: any;

	menuTree: BehaviorSubject<CustomMenuItem[]> = new BehaviorSubject<CustomMenuItem[]>([]);

	public registerMenu(arg: CustomRootMenuItem)
	{
		let items = this.menuTree.value;
		let item: CustomRootMenuItem = {
			...arg,
			parent_slug: '',
		}
		item = this._setSlug4Items(item) as CustomRootMenuItem;

		items.push(item);
		this.menuTree.next(items);
	}

	private _setSlug4Items(item: CustomMenuItem | CustomRootMenuItem): CustomMenuItem | CustomRootMenuItem
	{
		if (item.items)
		{
			for (let i in item.items)
			{
				let sub = item.items[i];
				sub.parent_slug = item.slug;
				if (!sub.slug) sub.slug = this._label2slug(sub.label!);

				this._setSlug4Items(sub);
			}
		}
		return item;
	}

	private _label2slug(str: string): string
	{
		str = str.toLocaleLowerCase();
		str = str.replace(/\s/gi, '-');
		str = str.replace(/[çÇ]/gi, 'c');
		str = str.replace(/[ÄÅÁÂÀÃäáâàã]/gi, 'a');
		str = str.replace(/[ÉÊËÈéêëè]/gi, 'e');
		str = str.replace(/[ÍÎÏÌíîïì]/gi, 'i');
		str = str.replace(/[ÖÓÔÒÕöóôòõ]/gi, 'o');
		str = str.replace(/[ÜÚÛüúûù]/gi, 'u');

		return str;
	}

	async montaMenu()
	{
		let roles: string[] = this.roles ?? [];
		let items: CustomMenuItem[] = await this.restringeItems(this.items.value, roles);
		this.menuTree.next(items);
	}

	async restringeItems(items: CustomMenuItem[], roles: string[]): Promise<CustomMenuItem[]>
	{
		let itemsSaida: CustomMenuItem[] = items;
		return itemsSaida;
	}

	montaMenuItems(parentSlug: string): CustomMenuItem[]
	{
		let items = this.items.value.filter(item => item.parent_slug == parentSlug);
		for (let i in items)
		{
			let item = items[i];
			item.items = this.montaMenuItems(item.slug!);
		}
		return items;
	}

	async montaMenu2()
	{
		let items: CustomMenuItem[] = [];
		let roles: string[] = this.roles ?? [];
		let abertos: CustomMenuItem[] = [];

		items.push(...abertos);

		// const roles = await this.authService.getMyRoles({ simples: true });
		// const roles = [''];
		// console.log(this.roles);
		let isAdmin: boolean = roles.includes('admin.all');
		let isGestor: boolean = roles.includes('gestor.all');
		let isGestorLocal: boolean = roles.includes('gestor.local');
		let isAuditor: boolean = roles.includes('aud.all');
		let isSad: boolean = roles.includes('sad.all');

		if (this.profile?.id)
		{
			items.push(
				...SustentabilidadeModule.menuItems,
				...GestorModule.menuItems,
			);
		}

		if (isGestor || isAdmin)
		{
			items.push(
				// ...IndicadoresModule.menuItems,
			);
		}

		if (isAdmin)
		{
			items.push({
				label: 'Administração',
				icon: 'pi pi-fw pi-cog',
				items: [
					...SustentabilidadeModule.adminMenuItems,
					// ...IndicadoresModule.adminMenuItems,
					...GestorModule.adminMenuItems,
				]
			},
			);
		}
		// console.log(this.profile);

		// items.push(
		// 	{
		// 		label: 'Auditoria',
		// 		icon: 'pi pi-fw pi-percentage',
		// 		roles: [
		// 			'aud.gestor',
		// 			'aud.membro',
		// 			'caut.membro',
		// 			'gestor.all',
		// 			// 'sad.membro',
		// 		],
		// 		items: [
		// 			{
		// 				label: 'Trilha: Objetos e Elementos de despesa',
		// 				icon: 'pi pi-fw pi-box',
		// 				routerLink: ['/contas', 'trilhas', 'classifica-objetos'],
		// 				disabled: false,
		// 			},
		// 			// {
		// 			// 	label: 'Critérios de andamento',
		// 			// 	icon: 'pi pi-fw pi-tags',
		// 			// 	routerLink: ['/pcaq', 'trilhas'],
		// 			// },
		// 			// {
		// 			// 	label: 'Atualização em lote',
		// 			// 	icon: 'pi pi-fw pi-bolt',
		// 			// 	routerLink: ['/pcaq', 'atualiza'],
		// 			// 	disabled: false,
		// 			// },
		// 		],
		// 	},
		// );

		if (environment.DISABLE_AUTH)
		{
			for (let item of items)
			{
				item.roles = undefined;
				if (item.items)
				{
					for (let subitem of item?.items)
					{
						subitem.roles = undefined;
					}
				}
			}
		}

		this.items.next(items);
	}
}
