<ng-container *ngIf="mostraMenu">
	<ng-container *ngIf="!logado">
		<p-button label="Entrar no sistema" icon="pi pi-sign-in" [disabled]="false" styleClass="p-button-info" (click)="login()"></p-button>
	</ng-container>
	<ng-container *ngIf="logado && !user">
		<p-button [loading]="true" label="Carregando..." [disabled]="true" styleClass="p-button-info"></p-button>
	</ng-container>
	<ng-container *ngIf="logado && user">
		<p-button icon="pi pi-ellipsis-v" iconPos="right" [label]="user.msProfile ? user.msProfile?.displayName : user.profile.nome" [disabled]="false" styleClass="p-button-info" (click)="menuUser.toggle($event)"></p-button>
		<p-overlayPanel #menuUser styleClass="menu-user-flutuante">
			<ng-template pTemplate="content">
				<div class="card"> 
					<stj-user-photo size="thumb"></stj-user-photo>
					<div class="dados">
						<div class="nome">{{user.msProfile ? user.msProfile?.displayName : user.profile.nome}}</div>
						<div class="email">{{user.msProfile ? user.msProfile?.mail : user.profile.email}}</div>
					</div>
				</div>
				<p-megaMenu [model]="menuItems" orientation="vertical" ></p-megaMenu >
				<!-- <p-button label="Sair" [disabled]="false" icon="pi pi-sign-out" styleClass="btn-sair p-button-secondary p-button-outlined p-button-text mt-3" (click)="logout()"></p-button> -->
			</ng-template>
		</p-overlayPanel>
	</ng-container>
</ng-container>