import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { Routes } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PageHomeComponent } from './areas/layout/components/page-home/page-home.component';
import { PageNotAuthorizedComponent } from './areas/layout/components/page-not-authorized/page-not-authorized.component';
import { PageNotFoundComponent } from './areas/layout/components/page-not-found/page-not-found.component';
import { authGuard } from './modules/auth/guards/auth.guard';
import { roleGuard } from './modules/auth/guards/role.guard';

export function HttpLoaderFactory(httpClient: HttpClient)
{
	return new TranslateHttpLoader(httpClient);
}
registerLocaleData(ptBr);

export const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{ path: 'home', component: PageHomeComponent },
	{ path: 'not-authorized', component: PageNotAuthorizedComponent },
	// { path: 'admin', loadChildren: () => import('./areas/admin/admin.module').then(m => m.moduleRoutes), canActivate: [authGuard,roleGuard], data: { roles: ['admin.all'] } },
	{ path: 'auth', loadChildren: () => import('./areas/auth/auth.module').then(m => m.moduleRoutes) },
	{ path: 'gestor', loadChildren: () => import('./areas/gestor/gestor.module').then(m => m.moduleRoutes), canActivate: [authGuard,roleGuard], data: { roles: ['gestor.all'] } },
	// { path: 'indicadores', loadChildren: () => import('./areas/indicadores/indicadores.module').then(m => m.moduleRoutes), canActivate: [authGuard,roleGuard], data: { roles: ['gestor.all'] } },
	{ path: 'sustentabilidade', loadChildren: () => import('./areas/sustentabilidade/sustentabilidade.module').then(m => m.moduleRoutes) },
	{ path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];
