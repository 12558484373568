import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { Subscription } from 'rxjs';
import { AuthModule } from './areas/auth/auth.module';
import { LayoutModule } from './areas/layout/layout.module';
import { MySharedModule } from './modules/shared/my-shared.module';
import { MyMessageService } from './modules/shared/services/my-message.service';

@Component({
	selector: 'stj-root',
	standalone: true,
	imports: [
		CommonModule,
		LayoutModule,
		MySharedModule,
		AuthModule,
		MessageModule,
		MessagesModule,
	],
	providers: [
		MessageService,
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy
{
	constructor (
		private primeNgConfig: PrimeNGConfig,
		private translateService: TranslateService,
		public msgService: MyMessageService,
	)
	{
	}

  title = 'AUD-Econobot';
	private langSubscription?: Subscription;

	ngOnInit(): void
	{
		this.primeNgConfig.ripple = true;
		const lang = 'pt'
		this.translateService.addLangs([lang]);
		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);

		this.langSubscription = this.translateService.get('primeng').subscribe((res: any) =>
		{
			this.primeNgConfig.setTranslation(res);
		});
	}

	ngOnDestroy(): void
	{
		if (this.langSubscription) this.langSubscription.unsubscribe();
	}
}

