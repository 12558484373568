{
  "name": "aud-sustentabilidade-angular",
  "version": "0.7.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --disable-host-check --host 0.0.0.0",
    "start:prod": "npm run build && node ./server.js",
    "build": "npm run versao-patch && ng build --configuration=production --aot=true --named-chunks=true --source-map=true",
    "postbuild": "npm run copy",
    "copy": "npm run delete && npx copyfiles --up=1 -a ./dist/browser/**/* ../nestjs-sustentabilidade/dist/angular/",
    "delete": "npx rimraf --preserve-root ../nestjs-sustentabilidade/dist/angular/browser",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "versao": "npm version minor --no-git-tag-version",
    "versao-patch": "npm version patch --no-git-tag-version",
    "package": "npm run versao && npm run build",
    "serve:ssr:aud-sustentabilidade-angular": "node dist/aud-sustentabilidade-angular/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.11",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/platform-server": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@angular/ssr": "^17.3.8",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "chart.js": "^4.4.1",
    "express-http-proxy": "^2.0.0",
    "hmacsha1": "^1.0.0",
    "http-proxy-middleware": "^2.0.6",
    "jwt-decode": "^4.0.0",
    "marked": "^9.0.0",
    "ngx-cookie-service": "^17.0.1",
    "ngx-markdown": "^17.1.1",
    "ngx-socket-io": "^4.6.1",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.0",
    "rxjs": "~7.8.0",
    "socket.io": "^4.7.5",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "copyfiles": "^2.4.1",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "rimraf": "^5.0.7",
    "typescript": "~5.2.2",
    "version": "^0.1.2"
  }
}
