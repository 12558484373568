<p-menubar [model]="menuItems" id="mainToolbar">
	<ng-template pTemplate="start">
		<a class="brand-toolbar" routerLink="/">
			<img class="logo" alt="Logomarca do STJ" src="/assets/img/logo_stj_sigla_justa.svg" />
			<ng-container>
				<span>{{title}}</span>
			</ng-container>
		</a>
	</ng-template>
	<ng-template pTemplate="end">
		<stj-menu-user></stj-menu-user>
	</ng-template>
</p-menubar>