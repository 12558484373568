import { Component } from '@angular/core';
import packageJson from '../../../../../../package.json';

@Component({
	selector: 'stj-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss'
})
export class FooterComponent
{
	year = (new Date()).getFullYear();
	public version: string = packageJson.version;
}
