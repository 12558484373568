import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MegaMenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';
import { environment } from '../../../../../environment/environment';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/modules/auth/services/models/user.model';

@Component({
	selector: 'stj-menu-user',
	templateUrl: './menu-user.component.html',
	styleUrl: './menu-user.component.scss'
})
export class MenuUserComponent implements OnInit, OnDestroy
{
	constructor (
		private authService: AuthService,
		private _sanitizer: DomSanitizer,
		private router: Router,
		private msgService: MyMessageService,
	)
	{
		this.authService.currentUser.subscribe(user =>
		{
			this.logado = !!user;
		})
	}

	logado?: any;
	sub?: Subscription;
	user?: any;
	imagem?: any;
	menuItems: MegaMenuItem[] = [];
	mostraMenu: boolean = !environment.DISABLE_AUTH;

	ngOnInit(): void
	{
		this.authService.profile.subscribe((profile: any) =>
		{
			this.user = profile;
			if (profile)
			{
				if (profile.msProfile) this.authService.getPhoto();
			}
			else
			{
				this.menuItems = [];
			}
		});
		this.authService.roles.subscribe(() =>
		{
			this.montaMenu();
			// console.log(mixedRoles);
		});
		// this.authService.unidades.subscribe(() =>
		// {
		// 	this.montaMenu();
		// 	// console.log(mixedRoles);
		// });
		this.authService.photo.subscribe(photo =>
		{
			if (!photo)
			{
				this.imagem = undefined;
				return;
			}
			let imagemUrl = `${photo.prefix}${photo.imagem}`;
			this.imagem = this._sanitizer.bypassSecurityTrustUrl(imagemUrl);
		})
	}

	ngOnDestroy(): void
	{
		if (this.sub) this.sub.unsubscribe();
	}

	login()
	{
		this.router.navigate(['/auth/login']);
		// this.authService.login();
	}

	async logout()
	{
		let res = await this.authService.logout();
		if (res.status)
		{
			this.msgService.add({ severity: 'success', summary: 'Logout efetuado com sucesso!' });
			this.authService.localLogout();
		}
	}
	
	async montaMenu()
	{
		let user: UserModel | undefined = this.authService.currentUser?.value;
		let hasSenha = !!user?.hasPassword;

		this.menuItems = [];
		this.menuItems.push({
			icon: 'pi pi-user',
			label: 'Meu perfil',
			routerLink: '/auth/profile',
			routerLinkActiveOptions: {}
		});
		this.menuItems.push({
			icon: 'pi pi-lock',
			label: 'Mudar minha senha',
			routerLink: '/auth/muda-senha',
			routerLinkActiveOptions: {}
		});
		
		// console.log(this.authService.unidades.value)
		if(await this.authService.hasRole(['Gestor.All']))
		{
			this.menuItems.push({
				label: 'Minhas unidades',
				icon: 'pi pi-cog',
				routerLink: '/gestor',
				disabled: true,
			});
		}
		this.menuItems.push({
			label: 'separador',
			icon: 'pi pi-user',
			expanded: true,
			separator: true
		});
		this.menuItems.push({
			icon: 'pi pi-sign-out',
			label: 'Sair',
			command: (event) =>
			{
				this.logout();
			}
		});
	}
}
