import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import localeExtraPT from '@angular/common/locales/extra/pt';
import localePT from '@angular/common/locales/pt';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation, withHashLocation } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { HttpLoaderFactory, routes } from './app.routes';
import { LogTerminalService } from './modules/admin/components/log-terminal.service';
import { AuthInterceptor } from './modules/auth/auth.interceptor';
import { PrimengModule } from './modules/shared/primeng/primeng.module';

registerLocaleData(localePT, 'pt', localeExtraPT);

const config: SocketIoConfig = { url: 'http://localhost:3000/', options: { autoConnect: false } };

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(
			MessageModule,
			MessagesModule,
			// NgxIndexedDBModule.forRoot(dbConfig),
			BrowserAnimationsModule,
			// LayoutModule,
			PrimengModule,
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			MarkdownModule.forRoot(),
			SocketIoModule.forRoot(config),
		),
		MessageService,
		LogTerminalService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		provideRouter(routes, 
			withHashLocation(),
			withEnabledBlockingInitialNavigation()
			// withDebugTracing(),
			// withRouterConfig({
				
			// })
		),
		// provideClientHydration(),
		provideHttpClient(withFetch()),
		{ provide: LOCALE_ID, useValue: "pt" },
		// { provide: LOCALE_ID, useValue: 'pt-BR' },
	]
};
