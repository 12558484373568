import { Component } from '@angular/core';

@Component({
  selector: 'stj-page-not-authorized',
  standalone: true,
  imports: [],
  templateUrl: './page-not-authorized.component.html',
  styleUrl: './page-not-authorized.component.scss'
})
export class PageNotAuthorizedComponent {

}
