import { Component } from '@angular/core';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';

@Component({
	selector: 'stj-page-home',
	standalone: true,
	imports: [
		MySharedModule,
		MarkdownModule,
	],
	providers: [
		MyMessageService,
		MarkdownService,
	],
	templateUrl: './page-home.component.html',
	styleUrl: './page-home.component.scss'
})
export class PageHomeComponent
{

}
