import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MySharedModule } from '../../modules/shared/my-shared.module';
import { PrimengModule } from '../../modules/shared/primeng/primeng.module';
import { MyMessageService } from '../../modules/shared/services/my-message.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuUserComponent } from './components/menu-user/menu-user.component';
import { UserPhotoComponent } from './components/user-photo/user-photo.component';
import { MenuService } from './services/menu.service';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		MenuUserComponent,
		// UserPhotoComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		PrimengModule,
		UserPhotoComponent,
		MySharedModule,
	],
	providers: [
		MenuService,
		MyMessageService,
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		// UserPhotoComponent,
		MenuUserComponent,
	]
})
export class LayoutModule { }
