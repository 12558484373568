<div class="footer-inner">
	<div class="container">
		<div class="flex flex-row align-items-center justify-content-between">
			<div class="copyright">
				Copyright © STJ/AUD/CAUT 2023 - {{year}}.
			</div>
			<div class="version">
				Versão {{version}}
			</div>
		</div>
	</div>
</div>
