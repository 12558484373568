import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppComponent } from '../../../../app.component';
import { CustomMenuItem, MenuService } from '../../../../areas/layout/services/menu.service';

@Component({
	selector: 'stj-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit
{
	constructor (
		protected appComponent: AppComponent,
		protected menuService: MenuService,
	)
	{
	}

	menuItems: MenuItem[] = [];
	title: string = '';

	ngOnInit(): void
	{
		// this.menuItems = this.appComponent.menuItems.value
		this.title = this.appComponent.title;
		this.menuService.items.subscribe(items =>
		{
			this.menuItems = this.removeItemsMenu(items, this.menuService.roles ?? []);
		});
	}

	private removeItemsMenu(items: CustomMenuItem[], roles: string[]): CustomMenuItem[]
	{
		let itemsSaida: CustomMenuItem[] = [];
		for (let i in items)
		{
			let item = items[i];
			if (item.roles?.length)
			{
				let intersect = this.intersect(item.roles, roles);
				if (!intersect.length) continue;
			}
			if (item.items) item.items = this.removeItemsMenu(item.items, roles);
			itemsSaida.push(item);
		}
		return itemsSaida
	}

	private intersect(a:string[], b:string[])
	{
		var setB = new Set(b);
		return [...new Set(a)].filter(x => setB.has(x));
	}
}
